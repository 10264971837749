import { Link } from "react-router-dom";

const Distance = () => {
    return (
        <>
            <div className="homeContainer">
                <h1 className="sectionTitle"> Assistance à distance </h1>

                <div className="tarifs">
                    <div className="tarifsTitre">
                        Ce qui peut s'effectuer à distance :
                    </div>
                    <br />
                    Les mises à jour de votre ordinateur ainsi que l'installation de logiciels peuvent s'effectuer à distance, sauf cas particuliers. <br /><br />
                    Afin de savoir si votre demande peut-être réalisée à distance, merci de me contacter au préalable :
                    <br /><br />
                    <Link className="mailLink" to="/contact">Contact</Link>

                </div>

                <div className="tarifs">
                    <div className="tarifsTitre">
                        Comment procéder ?
                    </div>
                    <br />
                    L'assistance à distance s'effectue grâce à l'application TeamViewer, en appel téléphonique. <br /><br />
                    Téléchargez l'application sur votre ordinateur et lancez-la. <br /><br />
                    Une fois l'application lancée, un identifiant et un mot de passe s'affichent. <br /><br />
                    Vous aurez simplement à me transmettre ces informations, je pourrai ensuite me connecter à votre ordinateur le temps de l'assistance à distance. <br /><br />

                    Pour télécharger TeamViewer, cliquez ici : 
                    <br /> <br />
                    <div style={ {position: 'relative', width: '234px', height: '60px', margin: '0 auto'}}>
                        <a href="https://www.teamviewer.com/link/?url=842558&id=1698844343552" style={{
                            textDecoration: 'none',
                        }}>
                        <img src="https://static.teamviewer.com/resources/badges/teamviewer_badge_flat4.png" alt="Télécharger TeamViewer Remote Control" title="Télécharger TeamViewer Remote Control" border="0" width="234" height="60" /></a>
                    </div>
                        
                </div>

                <h2 className="sectionTitle">Tarifs à distance : </h2>
                    <div className="tarifs">
                        <div className="tarifsTitre">
                            Tarifs à l’heure* : 25€/h
                            </div>
                        * Licences logiciels non comprises <br />
                        * La première heure est due <br />
                        * 12.50€ la 1/2h supplémentaire
                        <br /><br />
                        Intervention sous 24H
                        <br /><br />
                        Pour rappel, toute intervention n'est pas réalisable à distance. <br />
                        Contactez-moi pour plus d'informations. <br />
                    </div>
            </div>
        </>
    )
};

export default Distance;