import "./Demarches.scss";

const Demarches = () => {

    return (
        <>
            <div className="homeContainer">
                <h1 className="sectionTitle"> Démarches administratives </h1>

                <div className="tarifs">
                    <div className="tarifsTitre">
                        Nos services de démarches administratives :
                    </div>
                    <br />
                    Vous trouvez les procédures administratives en ligne complexes?
                    <br /><br />
                    Nous vous accompagnons dans toutes vos démarches en ligne, de manière claire et efficace.
                    <br /><br />
                    Gagnez du temps et de la tranquillité en nous confiant vos démarches administratives en ligne.
                    <br /><br />
                </div>

                <div className="tarifs">
                    <div className="tarifsTitre">
                        Quelques exemples :
                    </div>
                    <br />
                    Inscription en ligne : Création de compte, adhésion à des services en ligne.
                    <br /> <br />
                    Déclarations en ligne : Impôts, sécurité sociale, allocations...
                    <br /> <br />
                    Demandes en ligne : Certificats, renouvellement de permis...
                    <br /> <br />
                </div>
            </div>
        </>
    )
};

export default Demarches;