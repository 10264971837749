import "./Cours.scss";

const Cours = () => {

    return (
        <>
            <div className="homeContainer">
                <h1 className="sectionTitle"> Cours </h1>

                <div className="tarifs">
                    <div className="tarifsTitre">
                        Nos cours :
                    </div>
                    Envie d'apprendre à manier vos outils informatiques ? <br /><br />
                    Vous souhaitez pouvoir naviguer sur internet en toute sécurité ? <br /><br />
                    Vous ne comprenez pas comment vous servir de vos logiciels favoris ? <br /><br />
                    Je fais preuve de bienveillance et de pédagogie pour vous apprendre à réaliser au mieux toutes vos taches informatiques.
                </div>

                <div className="tarifs">
                    <div className="tarifsTitre">
                        Quelques exemples :
                    </div>
                    Comprendre et utiliser son système d'exploitation: Windows, Android (Samsung, Huawei, Wiko, ...), iOS (iPhone, iPad)
                    <br /><br />
                    Internet et communication: Navigation web, achats en ligne, démarches administratives, Cloud, réseaux sociaux, vidéoconférence, protection et confidentialité...
                    <br /><br />
                    Bureautique: E-mail, traitement de texte (CV, courriers,...), Tableur (Comptes, ...), Présentation assistée par ordinateur (Diaporama, ...)
                    <br /><br />
                </div>


            </div>
        </>
    )
};

export default Cours;