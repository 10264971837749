import { Outlet, Link } from "react-router-dom";
import "./Navbar.scss";
import { useState } from "react";
import { ReactComponent as Logo } from "../logo.svg";


const Layout = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const [isActive, setIsActive] = useState(false);

  const contactinfos = props.contactinfos;

  const handleDropdown = (event) => {
    document.getElementById("dropdown-menu").classList.toggle("hidden");
    setIsActive((current) => !current);
  };
  const handleMobileClick = (event) => {
    document.getElementById("collapseable").classList.toggle("collapse");
    setCollapsed((current) => !current);
  };

  window.onclick = function (e) {
    // ici on doit fermer le menu quand on clique en dehors et qu'il est ouvert
    let dropdown = document.getElementById("dropdown-menu");
    let collapseable = document.getElementById("collapseable");
    if ( 
      !e.target.matches("#dropDownButton") &&
      !dropdown.classList.contains("hidden") &&
      isActive 
    ) {
      dropdown.classList.add("hidden");
      setIsActive(false);
    }
    if (!e.target.matches("#collapseButton") && !e.target.closest('#collapseable') && !collapseable.classList.contains("collapse") && !collapsed) {
      collapseable.classList.add("collapse");
      setCollapsed(true);
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <Logo fill="#fff" className="logo" />
        <span className="navbar-title">T. LE PARE</span>
        <span
          className="navbar-toggler navbar-toggler-icon bi bi-grid-3x3-gap-fill"
          onClick={handleMobileClick} id="collapseButton"
        ></span>
        <div className="collapse navbar-collapse" id="collapseable">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to="/" onClick={handleMobileClick}>
                Accueil
              </Link>
            </li>
            <li className="nav-item dropdown">
              <button
                id="dropDownButton"
                className="nav-link btn dropdown-toggle"
                onClick={handleDropdown}
              >
                Services
              </button>
              <ul className="dropdown-menu hidden" id="dropdown-menu">
                <Link className="dropdown-item" to="/assistance" onClick={handleMobileClick}>
                  Assistance
                </Link>
                <Link className="dropdown-item" to="/depannage" onClick={handleMobileClick}>
                  Dépannage
                </Link>
                <Link className="dropdown-item" to="/demarches" onClick={handleMobileClick}>
                  Démarches
                </Link>
                <Link className="dropdown-item" to="/donnees" onClick={handleMobileClick}>
                  Données
                </Link>
                <Link className="dropdown-item" to="/cours" onClick={handleMobileClick}>
                  Cours
                </Link>
              </ul>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact" onClick={handleMobileClick} >
                Contact
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/distance"
                onClick={handleMobileClick}>
                Assistance à distance
              </Link>
            </li>
          </ul>
        </div>
      </nav>

      <div className="outletContainer">
        <Outlet />
      </div>

      <footer className="footer">
        <a href={"tel:" + contactinfos.tel} className="mailLink">
          <i className="bi bi-telephone"> {contactinfos.tel} </i>
        </a>
        <a href={"mailto:" + contactinfos.mail} className="mailLink">
          <i className="bi bi-envelope"> {contactinfos.mail} </i>
        </a>
        <Link
          className="mailLink"
          to="/legals"
        >
          Mentions légales
        </Link>
      </footer>
    </>
  );
};

export default Layout;
