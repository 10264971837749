import "./Contact.scss";

const Contact = (props) => {
  const contactinfos = props.contactinfos;

  return (
    <>
      <div className="homeContainer">
        <h1 className="sectionTitle"> Contact </h1>

        <div className="tarifs">
          <div className="tarifsTitre">Me contacter :</div>
          <br />
          Par SMS ou appel au :
          <br />
          <a href={"tel:"+ contactinfos.tel} className="mailLink">
            {contactinfos.tel}
          </a>
          <br />
          <br />
          En cas d'indisponibilité je vous rappellerai dans les meilleurs délais
          afin d'obtenir les premières informations en rapport avec votre
          demande.
          <br />
          <br />
          Par mail : <br />
          <a href={"mailto:"+ contactinfos.mail} className="mailLink">
            {contactinfos.mail}
          </a>
        </div>

        <div className="tarifs">
          <div className="tarifsTitre">Qui suis-je ?</div>
          <br />
          Mon prénom est Tristan, passionné d'informatique depuis mon plus jeune
          âge et étudiant en informatique à l'université Claude Bernard (Lyon
          1).
          <br />
          <br />
          Habitué à résoudre la majorité des problèmes informatiques auxquels
          mes proches et moi sommes confrontés, je saurai vous guider pour
          résoudre vos problèmes.
          <br />
          <br />
          J'ai également travaillé auprès de personnes agées au sein d'une
          résidence spécialisée et ai par conséquent acquis la pédagogie
          nécessaire à la transmission de connaissances.
          <br />
          <br />
        </div>
      </div>
    </>
  );
};

export default Contact;
