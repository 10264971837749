import { BrowserRouter, Routes, Route} from "react-router-dom";
import Navbar from "./pages/Navbar";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import Depannage from "./pages/Depannage";
import Assistance from "./pages/Assistance";
import Donnees from "./pages/Donnees";
import Cours from "./pages/Cours";
import Legals from "./pages/Legals";
import Distance from "./pages/Distance";
import Demarches from "./pages/Demarches";
import './App.scss';
import ScrollToTop from './components/scrolltotop';



export default function App() {

  const contactinfos = {
    tel: "06 99 54 43 14",
    mail: "tristan.lepare.pro@gmail.com"
  };
  const hebergeur = {
    nom: "o2switch",
    adresse: "https://www.o2switch.fr/",
};

  return (
    <BrowserRouter basename={"/"}>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Navbar contactinfos={contactinfos} />} >
          <Route index element={<Home />} />
          <Route path="/contact" element={<Contact contactinfos={contactinfos}  />} exact />
          <Route path="/depannage" element={<Depannage />} exact />
          <Route path="/assistance" element={<Assistance />} exact />
          <Route path="/donnees" element={<Donnees />} exact />
          <Route path="/cours" element={<Cours />} exact />
          <Route path="/demarches" element={<Demarches />} exact />
          <Route path="/distance" element={<Distance />} exact />
          <Route path="/legals" element={<Legals contactinfos={contactinfos} hebergeur={hebergeur}  />} exact />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}