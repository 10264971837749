import { Link } from "react-router-dom";
import './card.scss';
import { useState } from "react";

const Card = (props) => {


    const handleClick = (evt) => {
        //evt.stopPropagation();
        let card = evt.target.closest('.card');
        card.classList.toggle('is-flipped');
    }

    return (
        <>
            <div className="scene scene--card">
                <div onClick={handleClick} className="card">
                    <div className="card__face card__face--front">
                        <div className="title">
                            <img src={props.img} className="image" alt='' />
                            {props.frontTitle}
                        </div>
                        {props.front}
                    </div>
                    <div className="card__face card__face--back">
                        <div className="title">{props.frontTitle}</div>
                        <div className="content">{props.back}</div>
                        <Link to={props.link} className="lien">En savoir +</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Card;