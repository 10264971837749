import "./Depannage.scss";

const Depannage = () => {

    return (
        <>
            <div className="homeContainer">
                <h1 className="sectionTitle"> Dépannage </h1>

                <div className="tarifs">
                    <div className="tarifsTitre">
                        Nos services de dépannage :
                    </div>
                    <br />
                    Des messages d'erreurs incompréhensibles vous pourissent la vie?
                    <br /><br />
                    Vous n'arrivez plus à vous connecter à votre réseau? <br /><br />
                    Vous pensez être victime d'un virus informatique ? <br /><br />
                </div>

                <div className="tarifs">
                    <div className="tarifsTitre">
                        Quelques exemples :
                    </div>
                    <br />
                    Diagnostic complet
                    <br /><br />
                    Problèmes réseau (indépendants de l’opérateur)
                    <br /><br />
                    Erreurs logicielles
                    <br /><br />
                    Erreurs système d’exploitation, écran bleu …
                    <br /><br />
                    Suppression virus tenace
                    <br /><br />
                    Installation antivirus, analyse
                    <br /> <br />
                </div>
            </div>
        </>
    )
};

export default Depannage;