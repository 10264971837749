import "./Legals.scss";

const Legals = (props) => {
    const contactinfos = props.contactinfos;
    const hebergeur = props.hebergeur;

    return (
        <>
        <div className="homeContainer">
        <h1 className="sectionTitle">
            MENTIONS LÉGALES
        </h1>
            <div className="tarifs">
            <div className="tarifsTitre">
            Identité :
            </div>
            LE PARE Tristan, EI. <br />
            78 Rue Anatole FRANCE, 69100 VILLEURBANNE, France<br />
            Tel: {contactinfos.tel} <br />
            Mail: {contactinfos.mail} <br />
            SIRET: 977981901 00019 <br />
            <br /><br />
            <div className="tarifsTitre">
            Hébergement du site :
            </div> 
            {hebergeur.nom} <br />
            <a className="mailLink" href="https://www.o2switch.fr/">{hebergeur.adresse}</a> <br />
            <br /><br />
            </div>
            <div className="tarifs">
            <div className="tarifsTitre">
            Collecte de données personnelles :
            </div>
            Les données personnelles collectées sur ce site sont destinées à un usage interne de l'entreprise et ne seront pas partagées avec des tiers.<br />
            Conformément à la loi sur la protection des données, vous avez le droit d'accéder, de rectifier, ou de supprimer vos données en contactant {contactinfos.mail}. <br /><br />

            <div className="tarifsTitre">
            Cookies :
            </div>
            Ce site peut utiliser des cookies pour améliorer l'expérience de l'utilisateur. En naviguant sur ce site, vous consentez à l'utilisation de cookies. <br />
            </div>
        </div>
        </>
    )
};

export default Legals;