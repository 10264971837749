import "./Home.scss";
import Card from "../components/card";
import assistImg from '../img/assistance.jpg';
import coursImg from '../img/cours.jpeg';
import depannageImg from '../img/depannage.jpeg';
import donneesImg from '../img/donnees.jpeg';
import demarchesImg from '../img/demarches.jpeg';

export default function Home() {

    const assistance = {
        title: "Assistance",
        back:
            <>
                Une assistance informatique sur mesure pour répondre à vos besoins.
            </>,
    };

    const depannage = {
        title: "Dépannage",
        back:
            <>
                Résolution rapide de vos problèmes informatiques, à portée de clic.
            </>,
    };
    const donnees = {
        title: "Données",
        back:
            <>
                Protégez vos précieuses données avec notre expertise en sauvegarde et récupération.
            </>,
    };

    const cours = {
        title: "Cours",
        back:
            <>
                Apprenez et maîtrisez l'informatique à votre rythme grâce à nos cours personnalisés.
            </>,
    };

    const demarches = {
        title: "Démarches",
        back:
            <>
                Simplifiez vos procédures administratives en ligne avec notre assistance personnalisée.
            </>,
    };

    return (
        <>
            <div className="homeContainer">
                    <h1 className="sectionTitle">Dépannage et assistance informatique, PC, mobile et tablette
                        <br />
                        à domicile à Lyon, Villeurbanne, et à distance.
                    </h1>

                    <div className="cardContainer" >
                        <Card img={assistImg} frontTitle={assistance.title} front="Cliquez pour en savoir plus" back={assistance.back} link="/assistance"></Card>
                        <Card img={depannageImg} frontTitle={depannage.title} front="Cliquez pour en savoir plus" back={depannage.back} link="/depannage"></Card>
                        <Card img={demarchesImg} frontTitle={demarches.title} front="Cliquez pour en savoir plus" back={demarches.back} link="/demarches"></Card>
                        <Card img={donneesImg} frontTitle={donnees.title} front="Cliquez pour en savoir plus" back={donnees.back} link="/donnees"></Card>
                        <Card img={coursImg} frontTitle={cours.title} front="Cliquez pour en savoir plus" back={cours.back} link="/cours"></Card>
                    </div >

                    <h2 className="sectionTitle">Nos tarifs : </h2>
                    <div className="tarifs" id="mainTarifs">
                        <div className="tarifsTitre">
                            Tarifs à l’heure :
                            <br /> <br />
                            A domicile (sur Lyon et Villeurbanne) : <br />
                            49€ la première heure*
                            <br />
                            20€ la 1/2h supplémentaire
                        </div>
                        * 24.50€ après crédit d'impôt
                        <br />
                        * Licences logiciels non comprises
                        <br />
                        * La première heure est due
                        <br />
                        <br />
                        <div className="tarifsTitre">
                            A distance*: 25€/h
                        </div>
                        * non valable sur tous les types de services
                        <br /> <br />
                        Intervention sous 24H
                    </div>
                    <h2 className="sectionTitle">Crédit d'impôt </h2>
                    <div className="tarifs">
                        <div className="tarifsTitre">Bénéficiez de tarifs avantageux </div>
                        <br />
                        En tant qu’organisme de services à la personne, nous vous permettons de bénéficier du crédit d’impôt sur le revenu.
                        <br /><br />

                        Vous êtes non imposable? Vous serez crédité par l’administration fiscale, à hauteur de 50% des dépenses engagées.
                        <br /><br />
                        Vous êtes imposable et le montant éligible est supérieur à celui de votre impôt sur le revenu ? Vous bénéficiez d'une réduction d'impôt et êtes crédité du surplus par l’administration fiscale.
                    </div>

                    <div className="tarifs">
                        <div className="tarifsTitre">
                            Le crédit d'impôt
                        </div>
                        <br />
                        Il correspond à une somme déduite de votre impôt sur le revenu (IR) (Même si vous êtes non imposable)
                        <br /><br />
                        Il s’applique sur les prestations de services à la personne, dont l’assistance informatique à domicile.
                        <br /><br />
                        Il est égal à 50% des dépenses engagées quelque soit votre tranche d’imposition.
                        <br /><br />
                        Son plafond annuel pour les prestations d'assistance informatique à domicile s’élève à 3000€.
                    </div>

                    <div className="tarifs">
                        <div className="tarifsTitre">
                            Comment ça marche ?
                        </div>
                        <br />
                        Nous vous remettons avant le 31 mars de l'année N+1, une attestation récapitulant les prestations et leurs montants réglés lors de l'année N.
                        <br /><br />
                        Vous reportez le montant des dépenses présentées dans l’attestation dans votre déclaration de revenus.
                        <br /><br />
                        (Formulaire 2042 - Case 7DB, information susceptible d'évoluer)
                        Le montant vous est versé, au plus tard, à l'été de l'année de déclaration.
                    </div>
            </div>

        </>
    );
}