import "./Assistance.scss";

const Assistance = () => {

    return (
        <>
            <div className="homeContainer">
                <h1 className="sectionTitle"> Assistance </h1>

                <div className="tarifs">
                    <div className="tarifsTitre">
                        Nos services d'assistance :
                    </div>
                    <br /> <br />
                    Besoins de conseils ou d'aide ponctuelle ?
                    <br /> <br />
                    Un problème avec vos appareils?
                    <br /> <br />
                    Votre ordinateur est devenu lent au fil du temps? 
                    <br /> <br />
                    Besoin de conseils ou d'aide à la configuration de vos différents appareils électroniques ou de vos logiciels ? 
                    <br /> <br />
                    Je trouve avec vous une solution adaptée à vos besoins.
                </div>

                <div className="tarifs">
                    <div className="tarifsTitre">
                        Quelques exemples :
                    </div>
                    <br />
                    Assistance pour les novices: aide réservations en ligne, achats en lignes
                    <br /><br />
                    Mise à jour Pilotes, Logiciels, Système, Matériel
                    <br /><br />
                    Conseils (antivirus, logiciel, achat matériel)
                    <br /><br />
                    Configuration de matériel/services
                    <br /><br />
                    Paramétrage e-mail
                    <br /><br />
                    Optimisation système d’exploitation (‘mon ordinateur est trop lent’)
                    <br /><br />
                    Installation VPN
                    <br />
                    Utilisation et mise en marche appareils : TV Connectée, Scanner, imprimante, imprimante sans fil...
                    <br />
                    Installation de votre connexion internet
                    <br /><br />
                    Mise en route ordinateur personnel
                    <br />
                </div>


            </div>
        </>
    )
};

export default Assistance;