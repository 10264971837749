import "./Donnees.scss";

const Donnees = () => {

    return (
        <>
            <div className="homeContainer">
                <h1 className="sectionTitle"> Données </h1>

                <div className="tarifs">
                    <div className="tarifsTitre">
                        Nos services :
                    </div>
                        <br />
                        Vous avez supprimé un fichier important?
                        <br /><br />
                        Votre ordinateur ne démarre plus et vous n'avez pas sauvegardé vos fichiers?
                        <br /><br />
                        Vous souhaitez sauvegarder vos données à un endroit sûr ?
                        <br /><br />
                </div>

                <div className="tarifs">
                    <div className="tarifsTitre">
                        Quelques exemples :
                    </div>
                    <br />
                    Récupération de données (dans la mesure du possible)
                    <br /><br />
                    Sauvegarde des données (physique ou dans le cloud)
                    <br /><br />
                    Formatage et réinstallation du système
                    <br /><br />
                </div>
            </div>
        </>
    )
};

export default Donnees;